const lngs = [
  {
    code: 'en', // iso code
    name: 'English',
    country_code: 'us'
  },
  {
    code: 'ar', // iso code
    name: 'العربية',
    country_code: 'eg'
    // country_code: "sa"
    // dir: "rtl"
  }
];

export default lngs;
